/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"

import PropTypes from "prop-types"

import "./foundation.min.css"
import "./style.css"

const Layout = ({ children }) => {

  return (
    <div>
      <div className="grid-container">
        <div className="grid-x grid-padding-x">
          <div className="cell">
            <section className="programme">
              <div className="row margin-top"> 
                <main>{children}</main>
                
                <footer>
                  © {new Date().getFullYear()}, currently in development.
                </footer>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
